<script>
import Pagination from '@/components/general/Pagination'
import WorkspaceContentsTabTemplate from '@/components/workspace/WorkspaceContentsTabTemplate'
export default {
  name: 'WorkspaceContentsTab',
  components: {
    Pagination,
    WorkspaceContentsTabTemplate
  },
  data () {
    return {
      pagination: {
        limit: 16,
        page: 1,
        totalPages: 1,
        totalItems: -1
      },
      contents: [],
      appliedFilters: null
    }
  },
  watch: {
    'pagination.page' (page) {
      this.$router.push({ name: 'workspace.public.contents', query: { page } })
    },
    '$route' () {
      if (this.$route.name === 'workspace.public.contents') {
        if (this.$route.query.page) {
          this.pagination.page = parseInt(this.$route.query.page)
          this.updateContents(this.appliedFilters)
        } else {
          this.$router.push({ name: 'workspace.public.contents', query: { page: this.pagination.page } })
        }
      }
    },
    appliedFilters () {
      this.pagination.page = 1
    },
    '$route.params.id' () {
      if (this.$route.name === 'workspace.public.contents') {
        this.setup()
      }
    }
  },
  computed: {
    shouldForceOnlyPublic () {
      return this.$store.getters.getIndividualWorkspace.id !== parseInt(this.$route.params.id)
    }
  },
  methods: {
    async updateContents (filter = {}) {
      this.appliedFilters = filter
      const options = { filter: { ...filter, forceOnlyPublic: this.shouldForceOnlyPublic }, workspaceId: this.$route.params.id, limit: this.pagination.limit, page: this.pagination.page }
      const payload = await this.$store.dispatch('attemptGetWorkspaceContents', options)
      this.contents = payload.data
      this.pagination.totalPages = payload.totalPages
      this.pagination.totalItems = payload.total
      this.smoothScrollTop()
    },
    setup () {
      if (this.$route.query.page) {
        this.pagination.page = parseInt(this.$route.query.page)
        this.updateContents()
      } else {
        this.updateContents()
      }
    }
  },
  created () {
    this.setup()
  }
}
</script>
<template>
  <div class="workspace-content--tab">
    <workspace-contents-tab-template
      :contents="contents"
      @updateList="updateContents"
    ></workspace-contents-tab-template>
    <pagination
      v-if="this.pagination.totalItems"
      :pagination="pagination"
    ></pagination>
  </div>
</template>
